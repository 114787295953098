<template>
  <TTView>
    <VRow>
      <VCol>
        <SurveyCard
          :survey="survey"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import SurveyCard from '../components/SurveyCard.vue';

export default {
  name: 'SurveysView',

  components: {
    SurveyCard,
  },

  data() {
    return {
      survey: {},
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { surveyId: id } = this.$route.params;
        const data = { id };

        const surveyGetResponse = await this.$di.api.Engagement.surveysGet(data);

        this.survey = surveyGetResponse || {};
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
